<div *ngIf="!isLoading" class="filter-wrapper flex-row">
  <div class="flex-1 flex-row flex-wrap start-start column-gap-30">
    <!-- Color System -->
    <div *ngIf="selectedTab !== 2" class="filter-item flex-column end-start new-font-size">
      <div>{{ 'dashboard.colorSystem' | translate }}</div>
      <mat-button-toggle-group [value]="labOn" (change)="colorCoordinatesChanged($event)" aria-label="Font Style" class="margin-top">
        <mat-button-toggle [value]="true">Lab</mat-button-toggle>
        <mat-button-toggle [value]="false">LCH</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <!-- Tolerances -->
    <div class="filter-item flex-column end-start new-font-size">
      <div>{{ 'dashboard.tolerances' | translate }}</div>
      <mat-button-toggle-group [value]="tolerancesOn" (change)="tolerancesChanged($event)" aria-label="Font Style" class="margin-top">
        <mat-button-toggle [value]="true">{{ 'missing.on' | translate }}</mat-button-toggle>
        <mat-button-toggle [value]="false">{{ 'missing.off' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <!-- Angles -->
    <div
      *ngIf="chartData && chartData.angles && chartData.angles.length !== 0"
      class="filter-item flex-column gap-12 new-font-size"
    >
      <div>{{ 'dashboard.geometry' | translate }}</div>
      <div class="flex-row gap-10 margin-left--10">
        <mat-checkbox
          *ngFor="let angle of chartData.angles; let i = index"
          color="primary"
          [checked]="selectedAngles[i]"
          (change)="angleSelectionChanged($event, i)"
        >
          {{ angle }}°
        </mat-checkbox>
      </div>
    </div>
    <!-- Models -->
    <div *ngIf="modelsComponents?.models" class="filter-item flex-column gap-12 new-font-size">
      <div>{{ 'dashboard.models' | translate }}</div>
      <div class="flex-row flex-wrap gap-10 margin-left--10">
        <mat-checkbox
          *ngFor="let model of modelsComponents.models; let i = index"
          color="primary"
          [checked]="selectedModels[i]"
          (change)="modelSelectionChanged($event, i)"
        >
          {{ model }}
        </mat-checkbox>
      </div>
    </div>
    <!-- Export -->
    <div
      *ngIf="showExportButtons && (selectedTab === 0 || selectedTab === 1)"
      class="filter-item export-button flex-column gap-12 new-font-size"
    >
      <div>{{ 'dashboard.export' | translate }}</div>
      <div class="flex-row">
        <div class="flex-row gap-4p" *ngIf="selectedTab === 0">
          <div class="flex-row" [ngClass]="ccToken ? 'flex-48' : 'flex-100'">
            <button
                    mat-raised-button
                    class="icon-button flex-100"
                    [disabled]="clipboardLoading"
                    (click)="exportFMCCTBClipboardMeanValues()"
                    matTooltip=" {{ 'reports.downloadCpb' | translate }} ">
              <mat-icon class="icon" [inline]="true">download</mat-icon>
              <img alt="cpb" class="icon-img flex-100" src="assets/icon/cc_legacy_logo.png"/>
            </button>
          </div>
          <div *ngIf="ccToken" class="flex-row flex-48">
            <button
                    mat-raised-button
                    class="icon-button flex-100"
                    [disabled]="clipboardLoading"
                    (click)="linkToFMCCTBClipboardMeanValues()"
                    matTooltip="{{ 'reports.openInColorCareEvo' | translate }}">
              <mat-icon class="icon" [inline]="true">open_in_new</mat-icon>
              <img alt="ColorCARE evolution" class="icon-img flex-100 new-max-height" src="assets/icon/cc_evo_logo.png"/>
            </button>
          </div>
        </div>
        <div class="flex-row gap-4p" *ngIf="selectedTab === 1">
          <div class="flex-row" [ngClass]="ccToken ? 'flex-48' : 'flex-100'">
            <button mat-raised-button
                    class="icon-button flex-100"
                    [disabled]="clipboardLoading"
                    (click)="exportFMCCTBClipboardMeasurements()"
                    matTooltip="{{ 'reports.downloadCpb' | translate }}">
              <mat-icon class="icon" [inline]="true">download</mat-icon>
              <img alt="cpb" class="icon-img flex-100" src="assets/icon/cc_legacy_logo.png"/>
            </button>
          </div>
          <div *ngIf="ccToken" class="flex-row flex-48">
            <button
                    mat-raised-button
                    class="icon-button flex-100"
                    [disabled]="clipboardLoading"
                    (click)="linkToFMCCTBClipboardMeasurements()"
                    matTooltip="{{ 'reports.openInColorCareEvo' | translate }}">
              <mat-icon class="icon" [inline]="true">open_in_new</mat-icon>
              <img alt="ColorCARE evolution" class="icon-img flex-100 new-max-height" src="assets/icon/cc_evo_logo.png"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Components -->
  <div *ngIf="modelsComponents" class="flex-column gap-5 new-font-size"
   [ngClass]="modelsComponents.bumperComponents && modelsComponents.bodyComponents && showBodyComponents && showBumperComponents ? 'flex-700px' : 'flex-350px'">
    <div>{{ 'dashboard.components' | translate }}</div>
    <div class="flex-row gap-15 align-start">
      <mat-form-field
        *ngIf="modelsComponents.bodyComponents && showBodyComponents"
        [ngClass]="modelsComponents.bumperComponents && showBumperComponents ? 'flex-50' : 'flex-100'"
        appearance="fill"
        class="component-drop-down background-color-whitesmoke"
      >
        <mat-label class="component-drop-down">{{ 'dashboard.body' | translate }}</mat-label>
        <mat-select
          [formControl]="bodyComponentsFormControl"
          multiple
          panelClass="select-panel"
          (selectionChange)="bodyComponentSelectionChanged($event)"
        >
          <mat-select-trigger *ngIf="selectedBodyComponents.length === bodyComponentsFormControl.value.length">
            {{ 'dashboard.allSelected' | translate }}
          </mat-select-trigger>
          <div class="flex-row">
              <mat-checkbox
                [(ngModel)]="allBodyComponentsSelected"
                [ngModelOptions]="{ standalone: true }"
                (change)="selectAllBodyComponents($event)"
                color="primary"
                ><div class="">{{ 'dashboard.selectAll' | translate }}</div></mat-checkbox>
                <mat-checkbox
                        class="select-all-checkbox-20"
                        [(ngModel)]="contactPointsSelected"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="contactPointsChange()"
                        color="accent"
                ><div class="">CoPs</div></mat-checkbox>
                <mat-checkbox
                        class="select-all-checkbox-30"
                        [(ngModel)]="noContactPointsSelected"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="contactPointsChange()"
                        color="accent"
                ><div class="">no CoPs</div></mat-checkbox>
          </div>
          <mat-option *ngFor="let component of modelsComponents.bodyComponents; let i = index" [value]="component">
            <div class="flex-row start-center gap-5 font-color-black">
              <svg *ngIf="componentsLegend?.length !== 0" xmlns="http://www.w3.org/2000/svg" height="25" class="flex-17px">
                <!-- The path expressions describe the shape of the svg -->
                <path
                  [attr.fill]="chartComponentBorderColors[component]"
                  d="M9 6V0H8v6H0v13h8v6h1v-6h8V6H9zm7 12H1v-5h15v5zM1 12V7h15v5H1z"
                />
                <path
                  [attr.fill]="chartComponentColors[component]"
                  d="M1 7h15v5H1zM1 13h15v5H1z"
                />
              </svg>
              <span>
                {{ componentTranslations[component] ? componentTranslations[component]  : component }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="modelsComponents.bumperComponents && showBumperComponents"
        [ngClass]="modelsComponents.bodyComponents && showBodyComponents ? 'flex-50' : 'flex-100'"
        appearance="fill"
        class="component-drop-down background-color-whitesmoke"
      >
        <mat-label class="component-drop-down">{{ 'dashboard.bumper' | translate }}</mat-label>
        <mat-select
          [formControl]="bumperComponentsFormControl"
          multiple
          (selectionChange)="bumperComponentSelectionChanged($event)"
        >
          <mat-select-trigger *ngIf="selectedBumperComponents.length === bumperComponentsFormControl.value.length">
            {{ 'dashboard.allSelected' | translate }}
          </mat-select-trigger>
          <mat-checkbox
            class="select-all-checkbox"
            [(ngModel)]="allBumperComponentsSelected"
            [ngModelOptions]="{ standalone: true }"
            (change)="selectAllBumperComponents()"
            color="accent"
            ><label class="font-size-17">{{ 'dashboard.selectAll' | translate }}</label></mat-checkbox
          >
          <mat-option
            *ngFor="let component of modelsComponents.bumperComponents; let i = index"
            [value]="component"
            class="bumper-option"
          >
            <div class="flex-row start-center gap-5 font-color-black">
              <svg *ngIf="componentsLegend?.length !== 0" xmlns="http://www.w3.org/2000/svg" height="25" class="flex-17px">
                <path
                  [attr.fill]="chartComponentBorderColors[component]"
                  d="M9 6V0H8v6H0v13h8v6h1v-6h8V6H9zm7 12H1v-5h15v5zM1 12V7h15v5H1z"
                />
                <path [attr.fill]="chartComponentColors[component]" d="M1 7h15v5H1zM1 13h15v5H1z" />
              </svg>
              <span>
                {{ componentTranslations[component] ? componentTranslations[component]  : component }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { BreakPoints } from './services/mobile-check/breakpoints';
import { MobileCheck } from './services/mobile-check/mobile-check.service';
import { ThemeService } from './services/theme/theme.service';
import { TealiumUtagService } from './tealium/utag.service';
import {BoxAndWiskers, BoxPlotController} from '@sgratzl/chartjs-chart-boxplot';
import {ChartToleranceLinePlugin} from './shared/plugins/chart-tolerance-line.plugin';

@Component({
  selector: 'colimo-root',
  providers: [TealiumUtagService],
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private innerWidth: number;
  private bp: BreakPoints;

  constructor(
    private mobileCheck: MobileCheck,
    private themeService: ThemeService,
    private tealium: TealiumUtagService,
  ) {
    this.tealium.setConfig({ account: 'basf', profile: 'ecwebsites', environment: 'prod' });
  }

  public ngOnInit(): void {
    // Initialize tealium
    this.tealium.view({ event_name: 'init' });

    // Globally register BoxPlots and wiskers for chart-js (for lpg chart) and chart plugin for tolerance lines for all charts
    Chart.register(BoxPlotController, BoxAndWiskers, ChartToleranceLinePlugin);
    /*Chart.plugins.register(panDragPlugin()); // this seems to be old and is not use but we should check that later*/

    // Set initial values for global mobile data
    this.innerWidth = window.innerWidth;
    this.bp = this.mobileCheck.getBreakpoints();
    this.mobileCheck.setScreenSize({
      isMobile: this.innerWidth < this.bp.phone,
      isSmallTablet: this.innerWidth < this.bp.smallTablet && this.innerWidth > this.bp.phone,
    });
    this.themeService.loadTheme();
  }
}

/* eslint-disable max-lines */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';

import {ChartOptions, ScriptableScaleContext} from 'chart.js';
import Chart from 'chart.js/auto';
import { EnvironmentColors } from 'src/app/models/environment-colors.model';
import { ComponentGroups } from 'src/app/models/viewmodels/component-groups.model';
import { ChartStyle } from 'src/app/models/viewmodels/delta-e-chart.viewmodel';
import { ColorDataService } from 'src/app/services/color-data/color-data.service';
import { ChartScale } from '../../models/chart-scale.model';
import {ColimoPlotTooltipService} from '../../shared/services/colimo-plot-tooltip/colimo-plot-tooltip.service';

/**
 * Contains default configuration default options like labels, colors and behaviour of FOG chart.
 *
 * @see www.chartjs.org/docs/latest/charts/bubble.html
 *
 * @example The input dataset for this chart type is:
 * [
 *   {
 *     type: 'BODY'
 *     data: [{ x: -1, y: 0.2, component: 'HOOD' }, { x: 2, y: 3, component: 'FENDER_BACK_LEFT' }, ...]
 *   },
 *   { ... }
 * ]
 */
@Component({
  selector: 'colimo-fog-chart-component',
  templateUrl: 'fog-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FOGchartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public dataSet: any[];
  @Input() public dataLabels: string[];
  @Input() public dataScale: ChartScale;
  @Input() public activeComponents: ComponentGroups;
  @Input() public type: string;
  @Input() public chartComponentColors: string[];
  @Input() public chartMultiBatchColors: string[];

  // Directive to get chart access
  @ViewChild('chartFOG') public chartCanvas: ElementRef;
  private renderContext: CanvasRenderingContext2D;
  private chartInstance: Chart;

  // Colors definitions
  public environmentColors: EnvironmentColors;

  public isRendering = true;

  public bubbleChartData: ChartStyle[];

  public bubbleChartOptionsMain: ChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      // Tooltip styling www.chartjs.org/docs/latest/configuration/tooltip.html
      tooltip: {
        enabled: false,
        external: function(tooltipModel: any) {
          ColimoPlotTooltipService.handleCustomTooltip(tooltipModel, document, this.chart);
        }
      },
    },
    // Axes styling www.chartjs.org/docs/latest/axes/styling.html
    scales: {
      y:
        {
          grid: {
            color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
            lineWidth: 2,
          },
          title: {
            display: false,
          },
          max: 2,
          min: -2,
          ticks: {
            stepSize: 3,
          },
          border: {
            display: false
          },
        },
      x:
        {
          grid: {
            color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
            lineWidth: 2,
          },
          max: 3,
          min: -3,
          ticks: {
            stepSize: 3,
          },
          border: {
            display: false
          },
        },
    },
    animation: {
      duration: 300,
    },
  };

  public bubbleChartOptionsDL: ChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: function(tooltipModel: any) {
          ColimoPlotTooltipService.handleCustomTooltip(tooltipModel, document, this.chart);
        }
      },
    },

    layout: {
      padding: {
        left: 5,
        right: 5,
        bottom: 18,
      },
    },
    scales: {
      y:
        {
          grid: {
            color: (line: ScriptableScaleContext) => (line.tick.value === 0) ? '#7992AF' : 'transparent',
            lineWidth: 2,
          },
          title: {
            display: false,
          },
          max: 3,
          min: -3,
          ticks: {
            stepSize: 3,
          },
          border: {
            display: false
          },
        },
      x:
        {
          grid: {
            color: 'transparent',
          },
          ticks: {
            display: false,
          },
          border: {
            display: false
          },
        },
    },
    animation: {
      duration: 0,
    },
  };

  constructor(private colorDataService: ColorDataService) {}

  public ngOnInit(): void {
    this.isRendering = false;
    this.environmentColors = this.colorDataService.getEnvironmentColors();
    this.bubbleChartData = [];
  }

  public ngAfterViewInit(): void {
    this.renderContext = this.chartCanvas.nativeElement.getContext('2d');
    this.chartInstance = new Chart(this.renderContext, {
      type: 'bubble',
      data: {
        datasets: []
      },
      options: this.type === 'main' ? this.bubbleChartOptionsMain : this.bubbleChartOptionsDL
    });
  }

  public ngOnChanges(): void {
    this.nativeUpdateChartData();
  }

  /**
   * Create or update LineChartData Arrays and apply filters for BODY and BUMPER
   */
  private updateDataSets(): void {
    this.bubbleChartData = [];
    this.dataSet.forEach((set, i) => {
      const type = set ? set.type.toLowerCase() : 'no-data';

      switch (type) {
        case 'body': {
          if (this.activeComponents.bodyOn) {
            this.bubbleChartData.push(...this.getBubbleChartDatas(set));
          }
          break;
        }
        case 'bumper': {
          if (this.activeComponents.bumperOn) {
            this.bubbleChartData.push(...this.getBubbleChartDatas(set));
          }
          break;
        }
        default: {
          // No chart data
        }
      }
    });
  }

  /**
   * Populates chart data, labels and ticks on the native chartjs object, enables plugins.
   * Uses chartjs' update() method.
   */
  private nativeUpdateChartData(): void {
    if (!this.chartInstance) {
      return;
    }

    const config = this.chartInstance.config;

    this.updateDataSets();

    // Adjust x and y scales
    config.options.scales.y.min = this.dataScale.min;
    config.options.scales.y.max = this.dataScale.max;
    config.options.scales.x.min = this.dataScale.min;
    config.options.scales.x.max = this.dataScale.max;

    const yTicks = config.options.scales.y.ticks as any;
    const xTicks = config.options.scales.x.ticks as any;

    yTicks.stepSize = this.calculateStepSize();
    xTicks.stepSize = this.calculateStepSize();

    this.chartInstance.data.datasets = this.bubbleChartData;
    // Trigger chart update via API
    this.chartInstance.update();
  }

  private getBubbleChartDatas(set: any): ChartStyle[] {
    if (!set || !set.data) {
      return [];
    }

    const bubbleDatas = [];
    const type = set.type.toLowerCase();
    const bubbleData = {
      data: [],
      label: type,
      backgroundColor: type === 'bumper' ? this.environmentColors.lightPrimaryColor : this.environmentColors.primaryColor,
      borderColor: type === 'bumper' ? this.environmentColors.lightPrimaryColor : this.environmentColors.primaryColor,
      type: 'bubble'
    };

    for (let i = 0; i < set.data.length; i++) {
      // create a deep copy
      const data = JSON.parse(JSON.stringify(bubbleData));
      // change color according component colors if not all components are selected
      if (this.activeComponents.allBodySelected === false || this.activeComponents.allBumperSelected === false) {
        if (set.data[i] && set.data[i].component && this.chartComponentColors[set.data[i].component]) {
          data.backgroundColor = this.chartComponentColors[set.data[i].component];
          data.borderColor = this.chartComponentColors[set.data[i].component];
        }
      }

      // set multi batch colors if present which should only be the case in multi batch mode
      if (this.chartMultiBatchColors && this.chartMultiBatchColors[set.batchLineKey]) {
        data.backgroundColor = this.chartMultiBatchColors[set.batchLineKey];
        data.borderColor = this.chartMultiBatchColors[set.batchLineKey];
      }

      data.data.push(set.data[i]);
      bubbleDatas.push(data);
    }

    return bubbleDatas;
  }

  /**
   * Returns x- and y-axis labeling step size (i.e. 3, 5 etc.) as configuration options
   * @return stepSize to be used under the stepSize key in ticks
   */
  private calculateStepSize(): number {
    const delta = Math.round(Math.abs(this.dataScale.min) + Math.abs(this.dataScale.max));
    return delta / 2;
  }
}

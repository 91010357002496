<div [ngClass]="isMobile === true ? 'notification-wrapper-mobil' : 'notification-wrapper'">
    <colimo-page-header [heading]="'sidenav.alerts'"> </colimo-page-header>
    <mat-card appearance="outlined">
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" class="new-font-family">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon *ngIf="alertsConfigured" class="tab-icon">notification_important</mat-icon>
                    <mat-icon *ngIf="!alertsConfigured" class="tab-icon">notifications_off</mat-icon>
                    <span>{{ 'sidenav.alerts' | translate }}</span>
                </ng-template>
                <ng-template matTabContent>
                    <table mat-table matTableResponsive [dataSource]="alertUserData.content" class="mat-elevation-z8"
                           matSort (matSortChange)="sortEvent($event)">
                        <ng-container matColumnDef="alertLevel">
                            <th mat-header-cell *matHeaderCellDef> {{ 'notification.level' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.alertLevel >= 2000 ? 'red-cell' : 'yellow-cell' ">
                                <mat-icon>notification_important</mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="colorName">
                            <th mat-header-cell *matHeaderCellDef> {{ 'reports.reportTable.color' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.colorName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="productNr">
                            <th mat-header-cell *matHeaderCellDef> {{ 'reports.reportTable.product' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.productNr}} </td>
                        </ng-container>
                        <ng-container matColumnDef="batchNr">
                            <th mat-header-cell *matHeaderCellDef> {{ 'reports.reportTable.batch' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.batchNr}} </td>
                        </ng-container>
                        <ng-container matColumnDef="line">
                            <th mat-header-cell *matHeaderCellDef> {{ 'reports.reportTable.line' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.line}} </td>
                        </ng-container>
                        <ng-container matColumnDef="model">
                            <th mat-header-cell *matHeaderCellDef> {{ 'reports.reportTable.model' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.model}} </td>
                        </ng-container>
                        <ng-container matColumnDef="info">
                            <th mat-header-cell *matHeaderCellDef> {{ 'notification.info' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.info}} </td>
                        </ng-container>
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> {{ 'notification.createdAt' | translate }} </th>
                            <td mat-cell *matCellDef="let element" [ngClass]="element.readStatus === 0 ? 'bold-text' : ''"> {{element.createdAt | date: 'yyyy-MM-dd HH:MM' }} </td>
                        </ng-container>
                        <ng-container matColumnDef="linkToPlot">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="action-cell" (click)="routeToAlertDetails(element)" matTooltip="{{ 'notification.linkTooltip' | translate }}">
                                <mat-icon>open_in_new</mat-icon>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="hideAlert">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="red-cell action-cell" (click)="markAsHidden(element.alertId)" matTooltip="{{ 'notification.deleteTooltip' | translate }}">
                                <mat-icon>delete_forever</mat-icon>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="markAsRead(row)"></tr>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                                {{ 'notification.noData' | translate }}
                            </td>
                        </tr>
                    </table>
                    <mat-paginator [pageSizeOptions]="pageSizeOptions"
                                   [pageSize]="pageSize"
                                   [length]="totalSize"
                                   [pageIndex]="currentPage"
                                   (page)="pageEvent($event)"
                                   showFirstLastButtons
                                   aria-label="Select page of periodic elements">
                    </mat-paginator>
                    <button mat-raised-button class="read-button"
                            [disabled]="!alertUserData || !alertUserData.content || alertUserData.content.length < 1"
                            matTooltip="{{ 'notification.allReadTooltip' | translate }}"
                            (click)="markAllAsRead()"
                            color="primary">{{ 'notification.allRead' | translate }}
                    </button>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">settings</mat-icon>
                    <span>{{ 'notification.configuration' | translate }}</span>
                </ng-template>
                <ng-template matTabContent>
                    <div [ngClass]="isMobile === true ? '' : 'kpi-warnings-wrapper'">
                        <table mat-table>
                            <ng-container matColumnDef="KPIs">
                                <th mat-header-cell [colSpan]="4" *matHeaderCellDef>
                                    <div class="kpi-warning-label kpi-warning-header">{{ 'notification.kpiWarnings' | translate }}</div>
                                </th>
                            </ng-container>
                            <ng-container matColumnDef="All">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox color="primary" name="allKpis"
                                    (change)="alertKpiAllCheckboxChanged($event)"
                                    [checked]="alertConfig.kpiCpi === 2 && alertConfig.kpiBpi === 2 && alertConfig.kpiLpi === 2 && alertConfig.kpiApi === 2 && alertConfig.kpiChi === 2">
                                        <div class="bold-text">{{ 'notification.all' | translate }}</div>
                                    </mat-checkbox>
                                </th>
                            </ng-container>
                            <div *ngFor="let kpiWarning of kpiWarnings">
                                <ng-container matColumnDef="{{ kpiWarning.name }}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div class="flex-column">
                                            <div class="kpi-warning-label">{{ kpiWarning.name }}</div>
                                            <mat-checkbox color="primary" name="{{ kpiWarning.name }}"
                                                          (change)="alertCheckboxChanged($event, kpiWarning.value)"
                                                          [checked]="alertConfig[kpiWarning.value] === 2">
                                            </mat-checkbox>
                                        </div>
                                    </th>
                                </ng-container>
                            </div>
                            <tr mat-header-row *matHeaderRowDef="['KPIs', 'All']"></tr>
                            <tr mat-header-row *matHeaderRowDef="['CPI', 'TPI', 'LPI', 'API', 'CHI']"></tr>
                        </table>
                    </div>
                    <div class="flex-column checkbox-margin">
                        <div class="flex-row">
                            <mat-checkbox
                                    class="checkbox-min-width"
                                    color="primary"
                                    name="worstPart"
                                    (change)="alertCheckboxChanged($event, 'worstPart')"
                                    [checked]="alertConfig.worstPart === 2">
                                <div class="bold-text">{{ 'notification.worstPart' | translate }}</div>
                            </mat-checkbox>
                        </div>
                        <div class="flex-row">
                            <mat-checkbox
                                    class="checkbox-min-width"
                                    color="primary"
                                    name="cpiCp"
                                    (change)="alertCheckboxChanged($event, 'cpiCp')"
                                    [checked]="alertConfig.cpiCp === 2">
                                <div class="bold-text">{{ 'notification.cpiCp' | translate }}</div>
                            </mat-checkbox>
                        </div>
                        <button mat-raised-button class="save-button" color="primary" (click)="saveAlertUserConfig()">{{ 'notification.save' | translate }}</button>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>

<colimo-project-header
  *ngIf="!!project"
  [project]="project"
  [lineId]="chartFilters.lineId"
  [modelId]="chartFilters.modelId"
  [kpis]="headerKpiLine"
  class="project-header"
>
</colimo-project-header>

<colimo-modal [size]="'md'"></colimo-modal>

<div class="batch-page-wrapper flex-column gap-15">
  <mat-card appearance="outlined">
    <colimo-chart-filters
      [chartData]="chartData"
      [(chartFilters)]="chartFilters"
      [tolerancesOn]="tolerancesOn"
      [selectedAngles]="selectedAngles"
      [activeComponents]="activeComponents"
      [chartColors]="chartColors"
      [chartBorderColors]="chartBorderColors"
      [chartComponentColors]="chartComponentColors"
      [chartComponentBorderColors]="chartComponentBorderColors"
      [componentsLegend]="componentsLegend"
      [preselectedModelId]="preselectedModelId"
      [selectedTab]="selectedTab"
      (selectedAnglesChange)="updateSelectedAngles($event)"
      (activeComponentsChange)="updateActiveComponents($event)"
      (modelsComponentsChange)="updateModelsComponents($event)"
      [componentTranslations]="componentTranslations"
    ></colimo-chart-filters>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabSelected($event)" [selectedIndex]="selectedTab" class="new-font-family">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex-row gap-10">
            <span>
              {{ 'dashboard.mwg' | translate | tabIlluminant: chartData:selectedTab:0 }}
            </span>
            <span><mat-icon
              *ngIf="tinterAdditionsCount !== 0"
              class="tinter-addition-circle"
              [matTooltip]="'dashboard.withTinterAdditions' | translate"
              matTooltipPosition="above"
              matTooltipClass="tinter-addition-tooltip"
              >circle</mat-icon>
            <mat-icon *ngIf="batchComments?.length > 0"
              class="batch-comment-circle"
              [matTooltip]="batchCommentTooltip"
              matTooltipPosition="above"
              matTooltipClass="multiline-tooltip"
              >circle</mat-icon></span>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <colimo-mwg-chart-list
            [showChartFilters]="showChartFilters"
            [showChartTable]="showChartTable"
            [selectedAngles]="selectedAngles"
            [activeComponents]="activeComponents"
            [modelsComponents]="modelsComponents"
            [(chartFilters)]="chartFilters"
            (tolerancesChanged)="tolerancesChanged($event)"
            (chartDataChange)="chartDataChanged($event)"
          ></colimo-mwg-chart-list
        ></ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex-row gap-10">
            <span>
              {{ 'dashboard.fdg' | translate | tabIlluminant: chartData:selectedTab:1 }}
            </span>
            <mat-icon
              *ngIf="tinterAdditionsCount"
              class="tinter-addition-circle"
              [matTooltip]="'dashboard.withTinterAdditions' | translate"
              matTooltipPosition="above"
              matTooltipClass="tinter-addition-tooltip"
              >circle</mat-icon
            >
          </div>
        </ng-template>
        <ng-template matTabContent
          ><colimo-fdg-chart-list-component
            [selectedAngles]="selectedAngles"
            [activeComponents]="activeComponents"
            [modelsComponents]="modelsComponents"
            [(chartFilters)]="chartFilters"
            [chartComponentColors]="chartComponentColors"
            [chartComponentBorderColors]="chartComponentBorderColors"
            (tolerancesChanged)="tolerancesChanged($event)"
            (chartDataChange)="chartDataChanged($event)"
          >
          </colimo-fdg-chart-list-component></ng-template
      ></mat-tab>
      <mat-tab [label]="'dashboard.fog' | translate | tabIlluminant: chartData:selectedTab:2"
        ><ng-template matTabContent>
          <colimo-fog-chart-list-component
            [activeComponents]="activeComponents"
            [selectedAngles]="selectedAngles"
            [modelsComponents]="modelsComponents"
            [(chartFilters)]="chartFilters"
            (tolerancesChanged)="tolerancesChanged($event)"
            (chartDataChange)="chartDataChanged($event)"
            [chartComponentColors]="chartComponentColors"
          >
          </colimo-fog-chart-list-component
        ></ng-template>
      </mat-tab>
      <mat-tab [label]="'dashboard.lpg' | translate | tabIlluminant: chartData:selectedTab:3"
        ><ng-template matTabContent
          ><colimo-lpg-chart-list-component
            [selectedAngles]="selectedAngles"
            [activeComponents]="activeComponents"
            [chartColors]="chartColors"
            [chartBorderColors]="chartBorderColors"
            [chartComponentColors]="chartComponentColors"
            [chartComponentBorderColors]="chartComponentBorderColors"
            [outlierColor]="chartComponentOutlierColor"
            [modelsComponents]="modelsComponents"
            [(chartFilters)]="chartFilters"
            (tolerancesChanged)="tolerancesChanged($event)"
            (chartDataChange)="chartDataChanged($event)"
            (componentsLegendChange)="componentsLegendChanged($event)"
            [componentTranslations]="componentTranslations"
          >
          </colimo-lpg-chart-list-component></ng-template
      ></mat-tab>
    </mat-tab-group>
  </mat-card>
</div>

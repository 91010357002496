import { EnvironmentProfile } from '../app/models/environment-profile.enum';

export const environment = {
  production: false,
  profile: EnvironmentProfile.DEVELOPMENT,
  apiBasePath: '/colimoUser',
  apiTrendAnalysisPath: '/colimoWsTrendsCollector',
  apiVersionPath: '/api/v1',
  apiUserPath: '/u',
  mimeType: '.json',
  adminPath: '/admin/',
  baseAuthPath: '/auth',
  minimalValidationTimeLeftInSeconds: 28800,
  loginRoute: '',
  routeAfterLogin: '/color',
  defaultLanguage: 'en',
  languageParameter: 'language',
  showNewCarReport: true,
  showTrendAnalysisResults: true,
  trendAnalysisMaxPredictionDays: 60,
  trendAnalysisWeeklyThreshold: 9,
  colorCareRoute: 'colorCareLogin',
  colorCareLoginUrl: 'https://dev.color.basf.com/apps',
  colorCareEvoUserUrl: 'https://dev.db.colorcare.basf.com/eu/user',

  loginRedirection: {
    enabled: false,
    auto: true,
  },

  dashboard: {
    init: 'dashboard',
    add: 'dashboard/add',
    connectProject: 'dashboard/connectProject?',
    disconnectProject: 'dashboard/disconnectProject?',
    customerLines: 'dashboard/customerLines',
    customerModels: 'dashboard/customerModels',
  },

  search: {
    all: 'search',
  },

  project: {
    all: 'project',
    headerById: 'project/header/{projectId}',
    byId: 'project/{projectId}',
    projectPageSize: 4,
  },

  batches: {
    all: 'batch',
    byProjectId: 'batch/project/{projectId}',
    header: 'batch/header/{batchId}/{lineNumber}',
    forecastById: 'batch/forecast/{batchId}',
    updateForecastByBatchId: 'u/ccng/updateForecastBatchId',
    forecastMeasurementsByBatchId: 'batch/forecastMeasurements/{batchId}',
  },

  kpis: {
    byLine: 'kpi/batch/{batchId}/line/{lineNumber}',
  },

  charts: {
    mwg: 'batch/{batchId}/line/{lineId}/data-avg-filtered',
    fdg: 'batch/{batchId}/line/{lineId}/data-diff',
    fog: 'batch/{batchId}/line/{lineNumber}/data-pos',
    deg: 'batch/{batchId}/line/{lineNumber}/data-diff-abstractde',
    lpg: 'batch/{batchId}/line/{lineNumber}/data-lpg',
    modelsComponents: 'batch/{batchId}/line/{lineNumber}/models-components',
  },

  reports: {
    customers: 'lookupKeyText?lstaNr=0&typ=KUND&lang=EN',
    locations: 'lookupKeyText?lstaNr=0&typ=LSTA&lang=EN',
    carTypes: 'lookupKeyText?lstaNr={lstaNr}&typ=CAR&lang=EN',
    locationLines: 'lookupLstaLines?kundNr={customerId}',
    colors: 'lookupColors?lstaNr={locationId}',
    colorProducts: 'lookupColorProducts?kundNr={customerId}',
    kpiReport:
      'kpiReport?kundNr={customerId}&lines={lines}&products={products}&kpis={kpis}&startDate={startDate}&endDate={endDate}',
    carStatus: 'carStatus/{lstaNr}?carId={carId}',
    carStatusSearch: 'carStatus/search/{lstaNr}?carId={carId}',
    carBodyStyle: 'carStatus/outline?carBodyStyleId={carBodyStyleId}',
    uploadSingleCar: 'carStatus/uploadSingleCarMeasurementFile?cartype={cartype}&colorId={colorId}&lstaNr={lstaNr}',
    uploadSingleCarDownloadXlsx:
      'carStatus/uploadSingleCarMeasurementFileDownloadXlsx?cartype={cartype}&colorId={colorId}&lstaNr={lstaNr}',
    components: 'lookupKeyText?lstaNr=0&typ=PART&lang={language}',
    position: 'lookupKeyText?lstaNr=0&typ=POINT&lang={language}',
  },

  trendAnalysis: 'predictions/batch/{batchId}/line/{lineId}/indicator/{indicator}',

  backendInfo: 'serverInfo',

  tinterAdditions: 'tinterAdditions?batchId={batchId}&line={line}',
  tinterAdditionsCount: 'tinterAdditions/count?batchId={batchId}&line={line}',

  exportToClipboard: {
    exportFMCCTBClipboardMeanValues: 'exportFMCCTBClipboardMeanValues?batchId={batchId}&line={line}',
    exportFMCCTBClipboardMeasurements:
      'exportFMCCTBClipboardMeasurements?batchId={batchId}&line={line}&startDate={startDate}&endDate={endDate}',
  },

  linkToClipboard: {
    linkToFMCCTBClipboardMeasurements:
      'linkToFMCCTBClipboardMeasurements?batchId={batchId}&line={line}&startDate={startDate}&endDate={endDate}',
    linkToFMCCTBClipboardMeanValues: 'linkToFMCCTBClipboardMeanValues?batchId={batchId}&line={line}',
  },

  contactPage: {
    contactEmail: 'DigitalBusinessSolutionsEMEA@basf.com',
  },
};

import { Injectable } from '@angular/core';
import Chart from 'chart.js/auto';

@Injectable({
  providedIn: 'root'
})
export class ColimoPlotTooltipService {

  public static componentTranslations: string[] = [];

  constructor() { }

  static handleCustomTooltip(tooltipModel: any, document: Document, chart: Chart) {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = ColimoPlotTooltipService.createToolTipHtmlElement(document);
    }

    // Hide if no tooltip
    if (tooltipModel.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.tooltip.yAlign) {
      tooltipEl.classList.add(tooltipModel.tooltip.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints.length > 0) {

      const titleLines = tooltipModel.tooltip.title;

      var innerHtml = '<thead>';
      titleLines.forEach(function(title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      for (let i = 0; i < tooltipModel.tooltip.dataPoints.length; i++) {
        var colors = tooltipModel.tooltip.labelColors[i];
        var span = ColimoPlotTooltipService.getColorBox(String(colors.backgroundColor));
        let tooltipText = '';
        if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints[i] && tooltipModel.tooltip.dataPoints[i].raw) {
          tooltipText = tooltipModel.tooltip.dataPoints[i].raw.x + ', ' + tooltipModel.tooltip.dataPoints[i].raw.y + ' ' + ColimoPlotTooltipService.getComponentTranslationTooltip(tooltipModel.tooltip.dataPoints[i].raw.component);
        }
        innerHtml += '<tr><td>' + span + tooltipText + '</td></tr>';
      }
      innerHtml += '</tbody>';

      var tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    var position = chart.canvas.getBoundingClientRect();
    ColimoPlotTooltipService.styleHtmlElement(tooltipEl, tooltipModel, position);
  }

  static handleFDGCustomTooltip(tooltipModel: any, document: Document, chart: Chart) {
    // Tooltip Element
    var tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = ColimoPlotTooltipService.createToolTipHtmlElement(document);
    }

    // Hide if no tooltip
    if (tooltipModel.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.tooltip.yAlign) {
      tooltipEl.classList.add(tooltipModel.tooltip.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints.length > 0) {

      //const titleLines = tooltipModel.tooltip.title; // currently no title for fdg tooltip should be displayed
      const titleLines = [];

      var innerHtml = '<thead>';
      titleLines.forEach(function(title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      for (let i = 0; i < tooltipModel.tooltip.dataPoints.length; i++) {
        var colors = tooltipModel.tooltip.labelColors[i];
        var span = ColimoPlotTooltipService.getColorBox(String(colors.backgroundColor));
        let tooltipText = '';
        if (tooltipModel.tooltip.dataPoints && tooltipModel.tooltip.dataPoints[i] && tooltipModel.tooltip.dataPoints[i].dataset) {
          tooltipText = tooltipModel.tooltip.dataPoints[i].label + ', ' + tooltipModel.tooltip.dataPoints[i].formattedValue + ' ' + ColimoPlotTooltipService.getComponentTranslationTooltip(tooltipModel.tooltip.dataPoints[i].dataset.component);
        }
        innerHtml += '<tr><td>' + span + tooltipText + '</td></tr>';
      }
      innerHtml += '</tbody>';

      var tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    var position = chart.canvas.getBoundingClientRect();
    ColimoPlotTooltipService.styleHtmlElement(tooltipEl, tooltipModel, position);
  }

  private static createToolTipHtmlElement(document: Document): HTMLDivElement {
    var tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    tooltipEl.style.backgroundColor = "#000000";
    tooltipEl.style.borderColor = "#000000";
    tooltipEl.style.borderWidth = "thin";
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.borderStyle = "solid";
    document.body.appendChild(tooltipEl);
    return tooltipEl;
  }

  private static styleHtmlElement(htmlElement: HTMLElement, tooltipModel: any, position: DOMRect): void {
    // Display, position, and set styles for font
    htmlElement.style.opacity = '0.85';
    htmlElement.style.position = 'absolute';
    htmlElement.style.left = position.left + window.scrollX + tooltipModel.tooltip.caretX + 'px';
    htmlElement.style.top = position.top + window.scrollY + tooltipModel.tooltip.caretY - tooltipModel.tooltip.height * 1.5 + 'px';
    htmlElement.style.fontFamily = '"Helvetica Neue", Helvetica, Arial, sans-serif';
    htmlElement.style.fontSize = '12px';
    htmlElement.style.fontStyle = 'normal';
    htmlElement.style.color = '#FFFFFF';
    htmlElement.style.padding = 5 + 'px ' + 5 + 'px';
    htmlElement.style.pointerEvents = 'none';
    htmlElement.style.zIndex='999999';
  }

  private static getColorBox(backgroundColor: string): string {
    let htmlBox = '<div style=" float: left; height: 15px; width: 15px; border: 1px solid black; clear: both; margin-right: 5px; margin-top: 3.5px;';
    htmlBox += ' background: ' + backgroundColor + ';';
    htmlBox += '"></div>';
    return htmlBox;
  }

  public static getComponentTranslationTooltip(component: string): string {
    if (!component) {
      return '';
    }
    return ColimoPlotTooltipService.componentTranslations[component] ? ColimoPlotTooltipService.componentTranslations[component] : component;
  }
}
